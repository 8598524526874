import * as msal from '@azure/msal-browser';

export const msalConfig = {
	auth: {
		clientId: 'e2dec960-7dda-4496-a328-066a9b5eff38',
		authority:
			'https://login.microsoftonline.com/daf84c0a-e8bf-4a54-910c-90a081a561db',
		redirectUri: GetURL(),
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case msal.LogLevel.Error:
						console.error(message);
						return;
					case msal.LogLevel.Info:
						console.info(message);
						return;
					case msal.LogLevel.Verbose:
						console.debug(message);
						return;
					case msal.LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
		},
	},
};

function GetURL() {
	if (window.location.href.includes('localhost')) {
		return 'http://localhost:9000/customersupport';
	} else if (window.location.href.includes('staging')) {
		return 'https://greenzonegateway-staging.azurewebsites.net/customersupport';
	} else if (window.location.href.includes('azurewebsites')) {
		return 'https://greenzonegateway.azurewebsites.net/customersupport';
	} else if (window.location.href.includes('greenzonegateway.com/')) {
		return 'https://greenzonegateway.com/customersupport';
	} else {
		return 'http://localhost:9000/customersupport'; // fallback just incase something weird happens, no live redirect Auth
	}
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
	scopes: [
		'api://3e01f9c9-0872-4488-8253-2c42f433da97/Custom.WriteAll',
		'User.Read',
		'openid',
		'profile',
	],
};

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
export const tokenRequest = {
	scopes: [
		'api://3e01f9c9-0872-4488-8253-2c42f433da97/Custom.WriteAll',
		'User.Read',
		'openid',
		'profile',
	],
};
