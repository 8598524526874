import { msalConfig, loginRequest, tokenRequest } from './authConfig';
import * as msal from '@azure/msal-browser';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';

export const myMSALObj = new msal.PublicClientApplication(msalConfig);
export const IsLoggedIn$ = new BehaviorSubject(false);
export const User$ = new BehaviorSubject();

let username = '';

export function cachedUser() {
	if (sessionStorage.getItem('AuthDetails')) {
		User$.next(JSON.parse(sessionStorage.getItem('AuthDetails')).account);
	}
}

function loadPage() {
	/**
	 * See here for more info on account retrieval:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
	 */
	const currentAccounts = myMSALObj.getAllAccounts();
	if (currentAccounts === null) {
		return;
	} else if (currentAccounts.length > 1) {
		// Add choose account code here
		console.warn('Multiple accounts detected.');
	} else if (currentAccounts.length === 1) {
		username = currentAccounts[0].username;
		User$.next(resp.account);
	}
}

function handleResponse(resp) {
	if (resp !== null) {
		sessionStorage.setItem('AuthDetails', JSON.stringify(resp));
		username = resp.account.username;
		User$.next(resp.account);
		IsLoggedIn$.next(true);
	}
}

export function signIn() {
	myMSALObj
		.loginPopup(loginRequest)
		.then(handleResponse)
		.catch((error) => {
			console.error(error);
		});
}

export function signOut() {
	const logoutRequest = {
		account: myMSALObj.getAccountByUsername(username),
	};

	sessionStorage.clear();
	myMSALObj.logoutRedirect(logoutRequest);
}

export function getTokenPopup(request) {
	/**
	 * See here for more info on account retrieval:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
	 */

	if (sessionStorage.getItem('AuthDetails')) {
		request.account = JSON.parse(sessionStorage.getItem('AuthDetails')).account; // myMSALObj.getAccountByUsername(JSON.parse(sessionStorage.getItem('AuthDetails')).account.username);
	} else {
	}

	return myMSALObj
		.acquireTokenSilent(request)
		.then((data) => {
			sessionStorage.setItem('AuthDetails', JSON.stringify(data));
		})
		.catch((error) => {
			console.warn(
				'silent token acquisition fails. acquiring token using redirect'
			);
			if (error instanceof msal.InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				return myMSALObj
					.acquireTokenPopup(request)
					.then((tokenResponse) => {
						console.log(tokenResponse);
						return tokenResponse;
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				console.warn(error);
			}
		});
}

function seeProfile() {
	getTokenPopup(loginRequest)
		.then((response) => {
			callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
			profileButton.classList.add('d-none');
			mailButton.classList.remove('d-none');
		})
		.catch((error) => {
			console.error(error);
		});
}

function readMail() {
	getTokenPopup(tokenRequest)
		.then((response) => {
			callMSGraph(
				graphConfig.graphMailEndpoint,
				response.accessToken,
				updateUI
			);
		})
		.catch((error) => {
			console.error(error);
		});
}

// loadPage();
