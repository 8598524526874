// Anything exported from this file is importable by other in-browser modules.
import { signIn, signOut, getTokenPopup, myMSALObj, cachedUser, IsLoggedIn$, User$  } from './auth/authPopup.js';
// import { signIn, signOut, getTokenRedirect, myMSALObj } from './auth/authRedirect.js';
import { msalConfig, loginRequest, tokenRequest } from './auth/authConfig';

// export  myMSALObj();

export function login() {
	signIn();
}

export function logout() {
	signOut();
}


export function getSilentToken(){
	return getTokenPopup(loginRequest)
}

export function handleAuthentication(){
 return myMSALObj.handleRedirectPromise();
}

export function isTokenExpired(){
	if (sessionStorage.getItem('AuthDetails')) {
		cachedUser();

		var isExpired = new Date > new Date(JSON.parse(sessionStorage.getItem('AuthDetails')).expiresOn);

		if(isExpired) {
			IsLoggedIn$.next(false);
		} else {
			IsLoggedIn$.next(true);
		}

		return isExpired;
	} else {
		IsLoggedIn$.next(false);
		return true
	};
}

export const isLoggedIn$ = IsLoggedIn$;
export const user$ = User$;



